.navbar-toggler{
    border: none;
}
.card{
    --bs-card-cap-bg: inherit
}
.btn-primary{
    color: $white;
    &:hover{
        color: $white
    } 
    &.btn{
        &:active{
            color: $white
        }
    }
}