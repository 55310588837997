@use "@general/styles/components/variables" as variable;
.navbar{
    position: fixed;
    z-index: variable.$z_inex_level_1;
    contain: layout;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $white;
    border-bottom: 1px solid $gray-200;
    color: $gray-600;
    .toggle-main-menu{
        cursor: pointer;
        font-size: $font-size-lg;
        display: inline-block;
    }
    .lang-switcher{
        float: right;
        .dropdown-toggle{
            color: $gray-600;
        }
    }
}
