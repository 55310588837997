@use "@general/styles/bootstrap/boostrap_configuration" as bst;
.undecorated{
    border: none;
    background: inherit;
}
.fade{
    z-index: 5000;
}
body{
    padding: 0!important;
    overflow: auto!important;
}
video{
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.h-title{
    font-weight: bold;
    font-size: 2.5em;
    text-transform: uppercase;
    @include bst.media-breakpoint-down(lg) {
        text-align: center;
        font-size: 1.5em;
    }
}
.small-title{
    font-weight: bold;
    font-size: 2em;
    text-transform: uppercase;
    @include bst.media-breakpoint-down(lg) {
        text-align: center;
        font-size: 1.5em;
    }
}
.action-button{
    font-weight: bold;
    min-width: 300px;
    font-size: 1.5em;
    text-transform: uppercase;
}
.primary-text{
    font-size: 1.5em;
}
.float-left{
    float: left;
}
.no-break{
    white-space: nowrap;
    word-break: keep-all;
}
.new-line{
    white-space: pre;
}
[class^=icon-]{
    vertical-align: -0.12em;
}