

@use 'fonts/general' as font;
@import "fonts/roboto/roboto";
@import "bootstrap/bootstrap";
@import "general";
@import "components/main_content";
@import "components/main_menu";
@import "components/navbar";
@import "components/card_list";
@import "components/cookie_banner";
* {
    box-sizing: border-box;
}
body {
    min-height: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
    background-color: $gray-100;
    font-family: font.$global;
}
a{
    color: inherit;
}