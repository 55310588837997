$gray-100: #F9F9F9;
$gray-200: #F1F1F4;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$primary: #8cbb1c;
$navbar-toggler-focus-width: 0;
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-border-radius: 0;

$card-bg: #fff;
$card-border-color: $gray-200;
$dark: #000;
$accordion-button-active-bg: none;
$accordion-button-focus-box-shadow: none;
$accordion-button-active-color: $dark;
$font-weight-bolder: 700;
$spacer:1rem;
$spacers: (
  0: 0,
  1: $spacer * .25, 
  2: $spacer * .5,  
  3: $spacer * 1,   
  4: $spacer * 1.5, 
  5: $spacer * 3,
  6: $spacer * 8
);