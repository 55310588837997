@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('./Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('./Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Thin';
  font-style: normal;
  font-weight: 250;
  src: url('./Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Thin';
  font-style: italic;
  font-weight: 250;
  src: url('./Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: 300;
  src: url('./Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  font-style: italic;
  font-weight: 300;
  src: url('./Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 500;
  src: url('./Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  font-style: italic;
  font-weight: 500;
  src: url('./Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  font-style: normal;
  font-weight: 900;
  src: url('./Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  font-style: italic;
  font-weight: 900;
  src: url('./Roboto-BlackItalic.ttf') format('truetype');
}