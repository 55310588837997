@use "@general/styles/components/variables" as variable;
@use "@general/styles/bootstrap/boostrap_configuration" as bst;
@use "sass:map";

.cookie_banner{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: variable.$z_inex_level_9;
    .cookie_message_block{
        background-color: bst.$white;
        @include media-breakpoint-down(sm) {
            .btn{
                width: 100%;
                margin-left: 0px!important;
                margin-top: map.get(bst.$spacers, 4);
            }
        }
    }
    max-width: 450px;    
}
.cookie_banner_toggle{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: variable.$z_inex_level_9;
    background-color: rgba($color: bst.$white, $alpha: 0.9);
    width: 50px;
    height: 50px;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 33px;
}