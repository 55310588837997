.card-list{
    .card-header{
        .title{
            float: left;
            font-size: $h4-font-size;
        }
        .open-menu{
            float: right;
            font-size: $font-size-lg;
        }
        
    }
}