@use "@general/styles/components/variables" as variable;
@use "@general/styles/bootstrap/boostrap_configuration" as bst;
.main_menu {
    background-color: $white;
    position: fixed;
    z-index: 5000;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 10px;
    border-bottom: 5px solid bst.$primary;
    .header_logo {
        width: auto;
        height: 50px;
    }

    .lang-switcher {

        float: right;
        padding-top: 9px;
        .btn {
            border: none;
        }

        .dropdown-menu {
            min-width: 55px;
        }
    }

    @include media-breakpoint-down(md) {
        .lang-switcher {
            padding-top: 2px;
            .btn {
                font-size: 1.5rem;
            }
    
            .dropdown-menu {
                font-size: 1.5rem;
                z-index: variable.$z_inex_level_3;
            }
        }
    }
}